<template>
  <div class="">
    <div class="flex flex-center" style="padding: 20px;">
      <template v-if="image">
        <el-image
          :src="image"
          style="height: 200px;width: 200px;"
          fit="contain"
        ></el-image>
      </template>
      <template v-else>
        <i style="font-size: 160px;" class="el-icon-receiving"></i>
      </template>
    </div>
    <div class="sctp-tc fz-14">{{ message }}</div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: "empty",
  props: {
    message: {
      type: String,
      default: '暂无数据',
    },
    image: {
      type: String,
    }
  }
}
</script>

<style scoped>

</style>
